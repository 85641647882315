.add-container-btn{
    width: calc(var(--width) + 2px) ;
    height: var(--height);
    padding-left: 0.5em;
    padding-right: 0.5em;
    border: 1px dashed var(--cbi-primary);
    border: none;
    border-radius: 34px;
    opacity: 1;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    cursor: pointer;
    font: normal normal normal 15px/19px Roboto;
    color: var(--cbi-primary)
}


.bottom-cbi-text{
    text-align: center;
    color: var(--cbi-primary);
    font-size: 10px;
    font-weight: 600;
}

.btn-cbi{
    width: 328px;
    height: 50px;
    /* UI Properties */
    background: var(--cbi-primary) 0% 0% no-repeat padding-box;
    border-radius: 34px;
    border-color: transparent;
    color:#FFFFFFDE;
    cursor: pointer;
}

.btn-cbi:disabled{
    opacity: 0.8;
    cursor: default;
}
.qr-out canvas{
    transition: all 2s ease;
}

/* .box {
    background-image: repeating-linear-gradient(0deg, #ed184b, #ed184b 4px, transparent 4px, transparent 5px, #ed184b 5px), repeating-linear-gradient(90deg, #ed184b, #ed184b 4px, transparent 4px, transparent 5px, #ed184b 5px), repeating-linear-gradient(180deg, #ed184b, #ed184b 4px, transparent 4px, transparent 5px, #ed184b 5px), repeating-linear-gradient(270deg, #ed184b, #ed184b 4px, transparent 4px, transparent 5px, #ed184b 5px);
    background-size: 2px 100%, 100% 2px, 2px 100% , 100% 2px;
    background-position: 0 0, 0 0, 100% 0, 0 100%;
    background-repeat: no-repeat;
    } */

.box{
    background-image: url("data:image/svg+xml,%3csvg width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%' height='100%' fill='none' rx='25' ry='28' stroke='%23EC3463' stroke-width='3' stroke-dasharray='8' stroke-dashoffset='1' stroke-linecap='butt'/%3e%3c/svg%3e");
border-radius: 34px;
}